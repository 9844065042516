.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 1572px) {
  .projectsRow {
    flex-direction: column;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .tab{
  background-color: white
} */

#theSelf {
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#theSelf img {
  min-width: 300px;
  max-width: 400px;
  border-radius: 100%;
  -webkit-transition: opacity 10s ease-in-out;
  -moz-transition: opacity 10s ease-in-out;
  -o-transition: opacity 10s ease-in-out;
  transition: opacity 10s ease-in-out;
  position:absolute;
  }

#theSelf img.transparent {
    opacity: 0;
}

span {
  font-family: 'Staatliches';
  color: white;
  font-weight: lighter;
}

@keyframes theSelfFadeInOut {
  0% {
    opacity:1;
  }
  10% {
    opacity: .90;
  }
  20% {
    opacity: .80;
  }
  30% {
    opacity: .70;
  }
  40% {
    opacity: .60;
  }
  50% {
    opacity: .50;
  }
  60% {
    opacity: .40;
  }
  70% {
    opacity: .30;
  }
  80% {
    opacity: .20;
  }
  90% {
    opacity: .10;
  }
  100% {
    opacity:0;
  }
}

#theSelf img.top {
  animation-name: theSelfFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 4s;
  animation-direction: alternate;
}

.leftJust {
  text-align: center;
  background: #315B5D;
  color:rgb(255, 187, 0);
  padding: 1.75%;
}

.projectsRow {
  justify-content: center;
}

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  background: #1C2623;
  width: 80%;
  flex-direction: row;
  justify-content: center;
  margin-left: 10%;
}

.wrap-circles {
  display: flex;
  min-height: 50px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 200px;
  margin-left: 0%;
  margin-right:-5%;
}

.circle {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 10px;
  border-radius: 50%;
  background: #FFCDB2;
  overflow: hidden;
  background-image: conic-gradient(#ffbb00 100%, rgb(170, 167, 167) 0);
}

.footer {
  bottom: 2.5rem;
  width: 100%;
  padding: 3rem;
  color: white;
  background: #212529;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: smaller;
  text-align: center;
}

/* #navbarNav {
  position: fixed;
} */

.contactFormDiv   {
  padding: 20%;
  margin-top: 5rem;
  display: flex;
  background: #315B5D;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.myNames {
  text-align: center;
  margin-left: 2rem;
  padding-right: 2rem;
}

#rauoldmoore {
  font-family: 'Cinzel', 'Righteous';
}

#rudieg {
  font-family: 'Cookie', 'Righteous';
  font-size: larger;
}

.circle .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 115px;
  height: 115px;
  background: rgb(88, 86, 86);
  border-radius: 50%;
  font-size: 15px;
  font-weight: 300;
  color: rgb(255, 187, 0);
}

.ourNav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

#someWork {
  color:white;
}